import type { FormEncType } from "@remix-run/react";
import { useCallback } from "react";
import type { useFetcher as useFetcherRR } from "react-router-dom";
import type { SubmitTarget } from "react-router-dom/dist/dom";
import { $path } from "remix-routes";
import type { UseDataFunctionReturn } from "remix-typedjson";
import { useNotifications } from "~contexts/NotificationProvider.tsx";
import { useTypedFetcherWithPromise } from "~hooks/useTypedFetcherWithPromise.tsx";
import { useToast } from "~zd-ui";

type ActionOptions = {
	encType?: FormEncType;
};

export const createTypedActionFetcherFactory = <
	T,
	TData extends SubmitTarget,
	P extends Record<string, any> = object,
	Q extends Record<string, any> = P,
>(
	apiPath: string,
	actionOptions?: ActionOptions,
) => {
	type TwithNotifications = T & { _notifications?: any[] };
	type OutputType = UseDataFunctionReturn<TwithNotifications>;

	return (opts?: Parameters<typeof useFetcherRR>[0]) => {
		const {
			submit: submitFetcher,
			data,
			typedData,
			...rest
		} = useTypedFetcherWithPromise<OutputType>(opts);

		const { addNotification, addErrorNotification, addModalNotification } =
			useNotifications();

		const { showToast } = useToast();
		const submit = useCallback(
			async (formData: FormData, params?: P, query?: Q) => {
				const response = submitFetcher(formData, {
					method: "POST",
					action: $path(apiPath, params, query),
					...actionOptions,
				}) as UseDataFunctionReturn<OutputType>;
				return response;
			},
			[submitFetcher],
		);

		const submitData = useCallback(
			async (data: TData, params?: P, query?: Q) => {
				const response = submitFetcher(data, {
					method: "POST",
					action: $path(apiPath, params, query),
					...actionOptions,
				}) as UseDataFunctionReturn<OutputType>;
				return response;
			},
			[submitFetcher],
		);

		const submitDataWithPromise = async (
			data: TData,
			params?: P,
			query?: Q,
		): Promise<UseDataFunctionReturn<OutputType>> => {
			const response = (await submitFetcher(data, {
				method: "POST",
				action: $path(apiPath, params, query),
				...actionOptions,
			})) as UseDataFunctionReturn<OutputType>;

			const notifications = response?._notifications;

			if (notifications) {
				for (const i of notifications) {
					if (i.type === "success" || i.type === "error" || i.type === "info") {
						showToast({
							message: i.message,
							type: i.type,
							duration: i.duration || 2000,
						});
					} else if (i.type === "modal") {
						addModalNotification(i.message, i.header, undefined, undefined, {
							useMarkdown: i.useMarkdown,
							initialDelay: i.initialDelay || 0,
						});
					}
				}
			}
			return response;
		};

		return {
			submit,
			submitData,
			submitDataWithPromise,
			submitFetcher,
			data,
			typedData,
			...rest,
		};
	};
};
